.overflowY {
  overflow-y: auto;
}
.transparent {
  background: transparent !important;
}
.dashboard-content {
  height: 87vh;
  overflow: hidden;
}
.overalldashboard {
  height: 80vh;
}
.overall-chart1 {
  height: 40vh;
  overflow: hidden;
  border: none;
  margin-bottom: 2rem;
}
.module-chart1 {
  height: 47vh !important;
  overflow: hidden;
  border: none;
}
.cardHeight {
  height: 80vh !important;
}
@media only screen and (min-width: 1366px) {
  .cardHeight {
    height: 78vh;
  }
}

@media only screen and (min-width: 1600px) {
}

@media only screen and (min-width: 1920px) {
}

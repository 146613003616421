body {
  margin: 0;
  padding: 0;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

p {
  font-family: "Open Sans";
  margin: 0;
  font-size: 13px;
}

#dropdown-month,
#dropdown-project,
.h-25p {
  height: 25px;
}

.satellite {
  position: fixed;
  top: 250px;
  right: 30px;
  width: 30px;
  border-radius: 4px;
  background-color: #fff;
}

.satellite-div {
  border-top: 1px solid #ddd;
  text-align: center;
  padding: 2px 0 2px 0;
  cursor: pointer;
}

.dropdown-div {
  position: fixed;
  right: 100px;
  top: 10px;
  display: inline-flex;
  z-index: 9;
}

.dropdown-div1 {
  position: fixed;
  left: 170px;
  top: 10px;
  display: inline-flex;
  z-index: 9;
}

.sidebar-div2 {
  position: absolute;
  right: 40px;
  top: 160px;
  display: inline-flex;
  z-index: 9;
}

.sidebar-div3 {
  position: relative;
  top: 120px;
  left: 40px;
  display: inline-flex;
  z-index: 9;
}

.logout-div {
  position: fixed;
  right: 30px;
  top: 22px;
}

.back-div {
  position: fixed;
  top: 22px;
  margin-left: 12px;
  border-radius: 3px !important;
  color: white !important;
  background: #009979 !important;
  padding: 3px !important;
  width: 37px;
  font-size: 18px !important;
  justify-content: center;
  align-items: center;
  display: flex;
}

.logout {
  border-radius: 3px !important;
  color: white !important;
  background: #009979 !important;
  padding: 5px !important;
  font-size: 18px !important;
}

.li-items {
  padding: 10px 10px 0 0;
  font-size: 12px;
  color: "black";
  font-weight: 500;
}

.ul-items {
  padding: 10px;
  list-style: none;
  text-align: left;
  width: 100%;
  margin-bottom: 0;
}

.menu-bar {
  z-index: 100;
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  top: 50px;
  flex-direction: column !important;
  margin-left: 20px;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.pp-bar {
  z-index: 100;
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  /* top: 50px; */
  flex-direction: column !important;
  margin-left: 20px;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  background: rgba(181, 243, 193, 0.1) !important;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapbox-logo {
  display: none;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapbox-improve-map {
  display: none;
}

.mapboxgl-ctrl-compass {
  display: none;
}

.mapboxgl-ctrl-top-right {
  right: 20px !important;
  top: 50px;
  border: none;
}

.mapboxgl-ctrl-top-right:focus {
  outline: none;
}

.nav-left-menu {
  position: fixed;
  top: 50px;
  left: 40px;
  height: calc(100%-30%);
  font-size: 12px;
  margin: 10px 10px 0 0;
  background-color: #fff;
}

.nav-left-menu1 {
  position: relative;
  /* top: 50px;
  left: 40px; */
  height: calc(100%-30%);
  font-size: 12px;
  margin: 10px 10px 0 0;
  background-color: #fff;
}

.w-15r {
  width: 15rem !important;
}

.scroll-bar {
  overflow-y: auto !important;
  max-height: calc(100vh - 200px) !important;
  background-color: white;
}

.mapboxgl-canvas {
  width: 100% !important;
}

.brandImg {
  position: fixed;
  left: 20px;
  bottom: 20px;
  /*background-color: #807e7e;*/
  z-index: 2;
}

.calculation-box {
  /* height: auto;
  width: 160px; */
  position: fixed;
  bottom: 15px !important;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px;
  text-align: center;
  z-index: 2;
  width: 1000px !important;
  height: 280px;
}

.popup {
  height: auto;
  width: 350px;
  position: fixed;
  top: 80px;
  right: 85px;
  background-color: white;
  z-index: 10;
}

.img {
  object-fit: cover;
}

country-item-value img.flag {
  width: 5px;
  height: 20px;
}

.ui-widget .ui-widget {
  font-size: 1em;
}

.layers {
  background-color: "#009979";
  font-size: "15px";
  height: "40px";
  padding: "10px";
  color: "white";
}

.left-menu {
  width: "13rem";
  height: "12rem";
  margin-left: "20px";
}

.DTM {
  float: right;
}

#country {
  font-size: 15px;
}

.d-drop {
  height: "30px";
}

.widthdropdown {
  width: 200px !important;
}

.d1 {
  margin-right: 5px;
}

.pi-chevron-down {
  font-size: 10px !important;
}

.menu {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: #fafafa !important;
}

.menucolor {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  color: #fafafa !important;
  background-color: #009979;
}

.nav-div {
  margin-left: 20px !important;
}

.nav-card {
  background-color: #009979 !important;
  font-size: 15px !important;
  height: 40px !important;
  padding: 10px !important;
  color: white !important;
  font-weight: 700 !important;
}

.nav-sign {
  float: right;
}

.pi-times {
  font-size: 12px !important;
  font-weight: 1000 !important;
}

/* i:hover {
  color: black !important;
} */

.p-selectbutton .p-button.p-highlight {
  background: #009979 !important;
  border-color: #009979 !important;
  color: #ffffff !important;
}

/* .p-buttonset{
    /* display: flex !important;
    flex-direction: column !important; */

/* .comparebox {
width: 10px;
margin-top: 20%;
margin-left: 1%;
  } */
/* .comparebox{
    margin-left: 10px;
  } */

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

#profileview-class {
  bottom: -35px !important;
  right: 30px !important;
  background-color: #fff;
  width: 1000px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(15, 14, 14) transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

#tool1tip {
  padding: none !important;
}

.menudash {
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  color: black !important;
  background-color: #fafafa;
}

.chartbutton {
  display: flex;
  padding-bottom: 10px;
  justify-content: space-evenly;
}

.highlight {
  background-color: #009979 !important;
  border-color: #009979 !important;
  box-sizing: border-box;
  margin-right: 2px !important;
}

.highweight {
  background-color: orange !important;
  border-color: orange !important;
  box-sizing: border-box;
  margin-right: 2px !important;
}

.peed {
  width: 100%;
}

.peed .p-dropdown {
  height: 15px !important;
  width: 30px;
  margin-left: 10px;
}

.modFixed {
  position: fixed;
  right: 90px;
  top: 180px;
  background-color: white;
  font-size: 12px;
  width: 250px;
  border: 2px solid #009979;
  z-index: 5;
}

.modFixed1 {
  position: fixed;
  right: 90px;
  top: 80px;
  background-color: white;
  font-size: 12px;
  width: 750px;
  border: 2px solid #009979;
  z-index: 5;
}

.headsty {
  background-color: #009979 !important;
  font-size: 14px !important;
  height: 38px !important;
  padding: 10px !important;
  color: white !important;
  font-weight: 700 !important;
}

.volValue {
  float: right;
  font-size: 14px !important;
  padding: 5px !important;
  color: rgb(100, 100, 100);
}

.volType {
  float: left;
  font-size: 14px !important;
  padding: 5px !important;
  color: black;
}

.phigh {
  height: 32px;
  padding-left: 4px;
}

.bgrey-pad {
  background-color: rgb(235, 235, 235);
  margin-left: 8px;
  margin-right: 8px;
  padding: 10px;
}

.dropdown-align {
  margin-left: 8px;
  margin-right: 8px;
}

.fs13pl {
  font-size: 13px;
  padding-left: 5px;
  padding-bottom: 3px;
  padding-top: 2px;
}

.fs13p {
  font-size: 13px;
  padding-left: 5px;
  padding-bottom: 3px;
  padding-top: 10px;
}

.w100h30 {
  width: 100%;
}

.pt8 {
  padding-top: 8px;
}

.bgcol {
  background-color: #009979 !important;
  color: white !important;
  height: 30px;
  border: 1px solid #009979 !important;
}

.bgw {
  background-color: white !important;
  color: #009979 !important;
  height: 30px;
  border: 1px solid #009979 !important;
}

.dflexp {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 10px;
  padding-bottom: 10px;
}

.w-60 {
  width: 60px !important;
}

.project-summary {
  right: 80px;
  left: auto;
  top: 70px;
  width: 30rem !important;
  font-size: 15px;
}

.project-accordion .p-accordion .p-accordion-tab {
  position: relative;
}

.project-accordion .p-accordion .p-accordion-tab .download-icon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.project-accordion .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  display: none;
}

.project-accordion .p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}

.project-accordion .p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 15px;
  border: 0px;
  color: #333;
  background: #e5e5e5;
  font-weight: 600;
  font-size: 14px;
}

.project-accordion .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
.project-accordion .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
.project-accordion .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  background: #e5e5e5;
  border-color: #e5e5e5;
  color: #333;
}

.project-accordion .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none;
}

.project-accordion .p-accordion .p-accordion-content {
  padding: 10px;
  border: 5px solid #dee2e6;
}

.project-accordion .p-accordion .p-accordion-content h6 {
  font-weight: bold;
  font-size: 14px;
  color: #1b6adf;
}

.project-accordion .p-progressbar .p-progressbar-label {
  display: none;
}

.progress-bar-w {
  width: 93%;
  float: left;
}

.progress-bar-p {
  width: 6%;
  position: absolute;
  top: -5px;
  right: 0;
  float: right;
  font-size: 12px;
  text-align: right;
  font-weight: bold;
}

.progress-name {
  font-size: 13px;
  font-weight: bold;
  color: #333;
}

.progress-status {
  font-size: 11px;
  font-weight: bold;
  color: #999;
}

.progress-text {
  font-size: 13px;
  color: #333;
}

.piechart_size {
  height: 300px;
  width: 300px;
}

.text_styles {
  height: 40px;
  width: 450px;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 20px;
}

.p-inputtextarea p-inputtext p-component text_styles {
  height: 40px;
  width: 450px;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 20px;
}

.title-status {
  height: 40px;
  font-size: initial;
  width: 450px;
  align-items: center;
  padding-bottom: 26px;
  padding-top: 10px;
  padding-right: -74px;
  padding-left: 150px;
}

.h-40 {
  height: 40px !important;
}

.h-20 {
  height: 20px !important;
}

.w-300 {
  width: 300px !important;
}

.calculation-box .p-chart {
  height: 160px;
}

.graphadjust {
  margin-top: 100px;
}

.card_main {
  max-width: 900px;
  margin: auto;
}

.drop_down .p-dropdown {
  width: inherit !important;
  margin: 0 10px;
}

.table_data {
  padding-top: 15px;
}

.peed .p-splitbutton .p-splitbutton-defaultbutton {
  background: #009979 !important;
  border-color: #009979 !important;
}

.peed .p-splitbutton .p-button-icon-only {
  background: #009979 !important;
  border-color: #009979 !important;
}

.peed .p-button {
  background: #009979 !important;
  border-color: #009979 !important;
}

.table_data .card {
  width: 100%;
  margin: 20px 0 0;
}

.text_box {
  box-shadow: 0px 0px 5px 1px #cfcdcd;
  padding: 20px;
  border-radius: 7px;
  margin: 25px 0 0;
  padding-left: 10px;
}

.text_area textarea {
  width: 100%;
  border: 0;
  padding: 5px 0;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
}

/* .p-progressbar {
  position: relative;
  height: 10px;
  padding-top: 10px;
} */
.p-dialog-draggable .p-dialog-header {
  cursor: move;
  color: white;
  background: #42997e;
  height: 10px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}

.p-progressbar {
  position: relative;
  height: 10px;
  overflow: hidden;
  font-size: 0;
  font: 100;
  padding-top: 0px;
  padding-bottom: 10px;
}

.p-progressbar .value {
  font-size: 0px;
}

.chartbutton {
  padding-bottom: 0;
}

.cardvalue {
  width: 20px;
}

.Pie_design {
  position: fixed;
  right: 90px;
  top: 80px;
  background-color: white;
  font-size: 12px;
  width: 250px;
  border: 2px solid #009979;
  z-index: 5;
}

.text_style {
  padding: 10px;
  border-radius: 7px;
  margin: 25px 0 0;
  padding-left: 10px;
}

.potree_container {
  height: 100vh !important;
}

#elev-div {
  height: 55px;
  width: 260px;
  font-size: small;
  position: fixed;
  bottom: -35px;
  left: 70px;
  background-color: white;
  z-index: 10;
  opacity: 0.8;
}

#comment-div {
  height: 55px;
  width: 300px;
  position: fixed;
  font-size: small;
  bottom: -35px;
  right: 30px;
  background-color: white;
  text-align: center;
  z-index: 10;
  opacity: 0.8;
}

.ol-logo {
  display: none !important;
}

/* apexchart style */
.apexcharts-series,
.apexcharts-pie-series {
  cursor: pointer;
}
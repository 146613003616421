.module-card {
  width: 410px !important;
  height: 260px !important;
  margin-right: 20px;
}
.module-img {
  width: 410px !important;
  height: 180px !important;
  object-fit: contain;
  overflow: hidden;
}
.module-card1 {
  width: 410px !important;
  height: 80px !important;
  display: flex;
  align-items: center;
  margin: auto;
  margin-left: 30px;
}
.index-card {
  height: 86vh !important;
  border: none !important;
}
.index-head {
  height: 7vh !important;
  background: transparent !important;
}
.index-body {
  height: 84vh !important;
  overflow-y: auto;
}
.module-main {
  padding: 0px 60px;
  display: flex;
  flex-wrap: wrap;
}
.add-module {
  height: 82vh !important;
  overflow: hidden;
  padding: 20px 30px;
  overflow-y: auto;
}

.multiselect {
  height: 50px;
  width: 100%;
}
.feature-table {
  height: 33vh;
}
@media only screen and (max-width: 1365px) {
  .module-main {
    padding: 0px 0px !important;
  }
  .index-card {
    height: 84vh !important;
  }
  .add-module {
    height: 79vh;
  }
}
@media only screen and (min-width: 1366px) {
  .index-card {
    height: 79vh !important;
  }
}
@media only screen and (min-width: 1600px) {
  .index-card {
    height: 86vh !important;
  }
  .module-main {
    padding: 0px 120px !important;
  }
}
@media only screen and (min-width: 1680px) {
  .module-main {
    padding: 0px 0px !important;
  }
  @media only screen and (min-width: 1820px) {
    .module-main {
      padding: 0px 92px !important;
    }
  }
}

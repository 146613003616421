.content {
  /* height: calc(100vh - 100px); */
  overflow-x: hidden;
  overflow-y: auto;
}
.content-h-100 {
  height: calc(100vh);
  overflow-x: hidden;
  overflow-y: auto;
}
.btn-add-role {
  background: #42997e;
  font-weight: bold;
  font-size: 16px;
}

.btn-add-role.p-button {
  border-color: #42997e;
  padding: 6px 20px !important;
  border-radius: 0;
}

.btn-add-role.p-button:hover {
  background: #000;
  border-color: #000;
}

.view-link {
  color: #42997e;
  text-decoration: none;
  font-weight: bold;
}

.clr-primary {
  color: #42997e !important;
}

.btn-style {
  padding: 10px 25px !important;
  /* text-transform: uppercase; */
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  /* border: 0px; */
  border-radius: 4px;
}

.btn-primary,
.btn-primary:hover {
  background: #42997e !important;
  border-color: #42997e !important;
}

/* .btn-secondary,
.btn-secondary:hover {
  border-color: #42997e !important;
  color: #42997e !important;
  background: none !important;
} */

.btn-primary:focus {
  box-shadow: none !important;
}

.mandatory {
  color: red;
}

.form-error {
  color: #ff0000;
  font-size: 1rem;
  line-height: 1.1875rem;
}

.border-err {
  border: solid 1px #f00;
}
.uploadImg .p-fileupload .p-fileupload-content {
  padding: 20px !important;
}
.uploadImg .pi-image.i:hover {
  color: #ffffff !important;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #42997e !important;
}

.cesium-widget-credits {
  display: none !important;
}

.logo-bg {
  background: #fff;
  height: 58px;
  border-radius: 6px;
}
.divide-deliverables {
  border-top: solid 1px #ccc;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
}

.checkbox-clr-green .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #42997e;
  background: #42997e;
}

.checkbox-clr-green
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-highlight:hover {
  border-color: #42997e;
  background: #42997e;
}

.checkbox-clr-green
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-focus {
  border-color: #42997e;
  box-shadow: 0 0 0 0.05rem #71edc7;
}

.checkbox-clr-green
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box:hover {
  border-color: #42997e;
}
.rdt_TableRow,
.rdt_TableHeadRow {
  border: 1 !important;
}

/* Pilot Interface Styles */
.project-card {
  cursor: pointer;
}

.uploadfile {
  cursor: pointer;
}

/* Pilot Interface Styles */
.project-card {
  cursor: pointer;
}

/* CSS styles for the comment box highlight */
.comment-box-highlight1 {
  border: 2px solid #ff9800; /* Change the border color to your desired highlight color */
  padding: 8px;
}

.comment-box-highlight2 {
  border: 2px solid #ff9800; /* Change the border color to your desired highlight color */
  padding: 8px;
}

.comment-box-highlight3 {
  border: 2px solid #ff9800; /* Change the border color to your desired highlight color */
  padding: 8px;
}

.upload-icon {
  width: 24px;
}

.append-icon {
  width: 24px;
}

.fstatus-success {
  background: #13c56bd8 0% 0% no-repeat padding-box;
  border-radius: 15px 0px 10px 0px;
  opacity: 1;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-medium) var(--unnamed-font-size-12) / 18px Poppins;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal medium 12px/18px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
}

.is-deleted {
  display: none;
}

.spinner-box {
  position: absolute;
  left: 50%;
  top: 20%;
}

.delete-icon {
  background-color: #06b4c3;
  padding: 1px;
  border-radius: 50%;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: #06b4c3;
  padding: 25px;
  border-radius: 25%;
}

.icon-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.cross-icon,
.zoom-icon {
  position: relative;
  pointer-events: all;
}

.toolbar {
  position: fixed;
  top: 10;
  right: 0;
  background-color: #f8f8f8;
  border-left: 1px solid #ccc;
  width: 80px;
  height: 90%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
}

.tool-button {
  background-color: #fff;
  cursor: pointer;
}

.tool-button:hover {
  background-color: #e0e0e0;
}

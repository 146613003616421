.brands {
  left: 20px;
  bottom: 20px;
}

.admin {
  position: fixed;
  top: 0;
  height: 84px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 0px !important;
  z-index: 999 !important;
}

.Client-admin {
  margin-right: 10px;
  text-align: left;
}

.Client-admin p {
  margin: 0;
}

.w-200 {
  width: 200px;
}

.bold {
  font-weight: bold;
}

.center {
  display: flex;
  justify-content: center;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}
.custom-button {
  margin: 16px 18px 16px 16px;
  text-align: right;
}

.custom-button .button {
  width: 154px;
  height: 41px;
  background: #42997e 0% 0% no-repeat padding-box;
  border-radius: 2px;
  border-color: #42997e;
  opacity: 1;
}

.custom-button .p-button .p-button-icon-left {
  margin-right: 0.5rem;
  padding-left: 25px;
}

.custom-button .p-button-label {
  flex: 1 1 auto;
  padding-right: 18px;
}

.custom-button .p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background: #42997e;
  color: #ffffff;
  border-color: #42997e;
}

.custom-button .p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.br50 {
  border-radius: 50%;
}

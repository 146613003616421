.bg-light-color {
  background-color: #fff !important;
}
.bg-light-green {
  background-color: #d4fcf0 !important;
}
.bg-light-gray {
  background-color: #eee !important;
}
.br-0 {
  border-radius: 0px !important;
}
.border-none {
  border: none !important;
  box-shadow: none !important;
}
.text-primary-open {
  color: #ff9e19;
}
.text-primary-closed {
  color: #0ee5a6;
}
.text-primary-good {
  color: #17ff2c;
}
.text-primary-low {
  color: #14ecff;
}
.text-primary-medium {
  color: #ffff00;
}
.text-primary-high {
  color: #bb2626;
}
.text-primary-major {
  color: #bb2626;
}
.text-primary-minor {
  color: #ffff00;
}
.empty-data {
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  margin-top: -15px;
}
.selectroles {
  width: 50%;
  margin: 10% auto;
}
.rdt_TableHeadRow {
  background-color: #78d4d4 !important;
}
.p-breadcrumb {
  border: none !important;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #198754 !important;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: #198754 !important;
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #495057 !important;
}
.overflow-y {
  height: 85vh;
  overflow-y: auto !important;
}
.overflow-y-100 {
  height: 100vh;
  overflow-y: auto !important;
}
.swiper {
  height: 100%;
}
.offcanvas-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.secondary {
  background-color: #495057 !important;
}
.secondary2 {
  background-color: #e0a46c !important;
}
.p-connected-overlay-enter-done {
  z-index: 1050 !important;
}

.modal-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.p-calendar .p-inputtext {
  padding: 20px 10px;
}

.dashboard {
  height: 90vh;
  overflow-y: auto !important;
}

.customUploadInput {
  display: none;
}
.customUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border: 1px dashed;
}
.app-content {
  background-color: #f3f3f9;
  margin: 0 !important;
  padding: 0;
  height: 100vh;
}

.noBorderCard {
  border: none !important;
  box-shadow: none !important;
}

/* sidebar */

.activeSideBar {
  width: calc(100% - 200px) !important;
  height: 83vh;
  overflow: hidden;
}
.inActiveSidebar {
  width: calc(100% - 70px) !important;
  height: 83vh;
  overflow: hidden;
}

.side-show {
  width: 200px;
  height: 83vh;
}
.side-hide {
  width: 70px;
  height: 83vh;
}

.ri-align-left,
.ri-arrow-right-line,
.ri-home-2-fill,
.fa-users,
.fa-user-tie,
.ri-layout-grid-line {
  font-size: 16px !important;
}

.sidebar-list {
  font-size: 13px !important;
}

/* admin-dashboard */

.dashboard-buttons {
  font-size: 13px !important;
  margin-right: 10px;
  border: none !important;
}
.admin-select {
  font-size: 12px;
  margin-right: 10px;
}
.admin-card-head {
  font-size: 13px;
}

/* overall-dashboard */

.admin-overall-dashboard {
  height: 77vh;
  overflow-y: auto;
}
.admin-donut {
  height: 77vh !important;
  overflow: hidden;
}
.header-card {
  background-color: transparent !important;
}
.footer-card {
  background-color: transparent !important;
}
.card-border-none {
  border: none !important;
  border-radius: 0px !important;
}
.module-card-admin {
  height: 58vh !important;
  overflow: hidden;
}
.module-card-right-admin {
  height: 77vh !important;
}
.module-card-body-admin {
  overflow-y: auto;
}
.ri-map-pin-2-fill {
  color: #fc6060;
}
.mdi-circle-medium {
  color: #50c3e6;
}

.ptable {
  height: 85vh;
  overflow-y: auto;
}

.column-title {
  font-size: 13px;
}
.pi-margin {
  margin-top: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .p-calendar {
    height: 25px !important;
    width: 120px;
  }
  .p-calendar-w-btn-right .p-inputtext {
    font-size: 12px;
  }

  .rdt_TableCell {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 1400px) {
  .activeSideBar {
    width: calc(100% - 250px) !important;
    height: 90vh;
    overflow: hidden;
  }
  .inActiveSidebar {
    width: calc(100% - 70px) !important;
    height: 90vh;
    overflow: hidden;
  }

  .side-show {
    width: 250px;
    height: 90vh;
  }
  .side-hide {
    width: 70px;
    height: 90vh;
  }
  .ri-align-left,
  .ri-arrow-right-line,
  .ri-home-2-fill,
  .fa-users,
  .fa-user-tie,
  .ri-layout-grid-line {
    font-size: 22px !important;
    margin-right: 10px;
  }
  .sidebar-list {
    font-size: 16px !important;
  }

  /* admin-dashboard */

  .dashboard-buttons {
    font-size: 15px !important;
    margin-right: 10px;
    border: none !important;
  }
  .admin-select {
    font-size: 13px;
    margin-right: 10px;
  }
  .admin-card-head {
    font-size: 15px;
  }

  /* overall-dashboard */

  .admin-overall-dashboard {
    height: 80vh;
    overflow-y: auto;
  }
  .admin-donut {
    height: 80vh !important;
    overflow: hidden;
  }
  .header-card {
    background-color: transparent !important;
  }
  .footer-card {
    background-color: transparent !important;
  }
  .card-border-none {
    border: none !important;
    border-radius: 0px !important;
  }
  .module-card-admin {
    height: 50vh !important;
    overflow: hidden;
  }
  .module-card-right-admin {
    height: 80vh !important;
  }
  .module-card-body-admin {
    overflow-y: auto;
  }
  .pi-margin {
    margin-top: 130px;
  }
}

@media screen and (min-width: 1600px) {
  .activeSideBar {
    width: calc(100% - 250px) !important;
    height: 90vh;
    overflow: hidden;
  }
  .inActiveSidebar {
    width: calc(100% - 70px) !important;
    height: 90vh;
    overflow: hidden;
  }

  .side-show {
    width: 250px;
    height: 90vh;
  }
  .side-hide {
    width: 70px;
    height: 90vh;
  }
  .ri-align-left,
  .ri-arrow-right-line,
  .ri-home-2-fill,
  .fa-users,
  .fa-user-tie,
  .ri-layout-grid-line {
    font-size: 22px !important;
    margin-right: 10px;
  }
  .sidebar-list {
    font-size: 16px !important;
  }

  /* admin-dashboard */

  .dashboard-buttons {
    font-size: 14px !important;
    margin-right: 10px;
    border: none !important;
  }
  .admin-select {
    font-size: 14px;
    margin-right: 10px;
  }
  .admin-card-head {
    font-size: 16px;
  }

  /* overall-dashboard */

  .admin-overall-dashboard {
    height: 80vh;
    overflow-y: auto;
  }
  .admin-donut {
    height: 75vh !important;
    overflow: hidden;
  }
  .header-card {
    background-color: transparent !important;
  }
  .footer-card {
    background-color: transparent !important;
  }
  .card-border-none {
    border: none !important;
    border-radius: 0px !important;
  }
  .module-card-admin {
    height: 50vh !important;
    overflow: hidden;
  }
  .module-card-right-admin {
    height: 80vh !important;
  }
  .module-card-body-admin {
    overflow-y: auto;
  }
  .pi-margin {
    margin-top: 130px;
  }
  .p-calendar {
    height: 43px !important;
    width: 150px;
  }
  .p-calendar-w-btn-right .p-inputtext {
    font-size: 13px;
  }

  .rdt_TableCell {
    font-size: 13px !important;
  }
  .column-title {
    font-size: 15px;
  }
}
.box-img {
  height: 70vh;
  width: 70vh;
  object-fit: contain;
}
.next-img {
  position: absolute;
  right: 130px;
  top: 50%;
}
.priv-img {
  position: absolute;
  top: 50%;
  left: 100px;
}

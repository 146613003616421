body {
  overflow: hidden;
}

body * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapbox-logo {
  display: none;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapbox-improve-map {
  display: none;
}

.mapboxgl-ctrl-compass {
  display: none;
}
.ol-swipe button {
  height: 100px;
  width: 100px;
  background: url("../assets/svg-new/swipe.svg");
  background-position: center;
  background-repeat: no-repeat;
  content: "";
}
.ol-swipe:after,
.ol-swipe button:before,
.ol-swipe button:after {
  background: transparent !important;
}

.left-select {
  position: absolute;
  top: 20px;
  left: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-select {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.br-2{
  border-radius: 10px;
}
.compare-logo{
  position: absolute;
  top: 10px;
  left: 10px;
}
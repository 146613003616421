.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
  cursor: default;
  user-select: none;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}

.dropdownMeasure {
  position: absolute;
  display: inline-block;
  right: 10px;
  bottom: 10px;
  z-index: 3;
  background-color: antiquewhite;
}
.map {
  width: 100%;
  height: 400px;
}
.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
  cursor: default;
  user-select: none;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #000;
  color: #fff;
  border: 1px solid white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: #000;
}

.calculation-box {
  position: absolute;
  display: inline-block;
  left: calc(100vw - 1000px);
  bottom: 0;
  z-index: 3;
  background-color: antiquewhite;
}
.globeIcon {
  position: absolute;
  display: inline-block;
  margin-top: 20%;
  margin-left: 95.5%;
  z-index: 15;
}
.ol-rotate {
  margin-top: 40%;
  margin-right: 95%;
}
.ol-rotate-reset {
  size: 50% !important;
  width: 0px !important;
  background-color: #ffffff08 !important;
}
.toolsIcon {
  position: absolute;
  display: inline-block;
  margin-top: 37%;
  margin-left: 95.5%;
  z-index: 15;
}
.toolcard {
  position: absolute;
  display: inline-block;
  margin-top: 35%;
  margin-left: 85.5%;
  background-color: rgb(100, 98, 98);
  border: #ffcc33;
  width: 10%;
  height: 30%;
  z-index: 15;
}

.ol-zoom {
  margin-top: 37%;
  margin-left: 92%;
  size: 30% !important;
}
.ol-control{
  background-color: transparent !important;
}

icon-tool-box {
  padding-top: 8;
  border-width: 0;
  color: "black";
  font-size: "0.7rem";
  padding-bottom: 8;
}
.icon-grid1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Create 3 columns with equal width */
  /* grid-gap: 10px; /* Add a gap of 10px between grid items */
  /* display: inline-block; */
  position: absolute;
  top: 45%;
  right: 5%;
  background-color: white;
  width: 6%;
  height: 24%;
  z-index: 15;
  padding: 10px;
}

.custom-rotate-control img {
  width: 35px;
  margin: 5px auto;
}
.ol-compass {
  background-color: transparent;
  width: 40px;
  position: relative;
  left: -15px;
  bottom: 20px;
  border-radius: 50%;
}
.inactive {
  display: none;
}
.videopanel {
  position: absolute;
  top: 9%;
  left: 0;
  background-color: #fff;
  z-index: 158;
  padding: 10px;
  height: 100vh;
  overflow-y: auto;
}
.MuiToolbar-regular {
  min-height: 0 !important;
}
.react-draggable .MuiContainer-maxWidthMd {
  max-width: 850px !important;
}
.videoListSidebar:hover {
  background-color: #eee;
}
.imagefit {
  width: 160px;
  height: 70px;
  overflow: hidden;
}
.imagefit img{
  object-fit: contain;
}
.rotate-tooltip{
  position: fixed;
    bottom: 15%;
    left: 72%;
    background: #FFFAD0;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #019D66;
    z-index: 2;
}
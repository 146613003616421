.primary-table {
  border: solid 1px #ccc;
}

.primary-table .p-datatable .p-datatable-thead > tr > th {
  font-size: 15px;
  background: #e8e8e8;
  border-color: #fff;
  padding: 6px;
}

.primary-table .p-datatable .p-datatable-thead > tr > th .p-fluid .p-inputtext {
  height: 40px;
  font-size: 14px;
  line-height: normal;
}

.primary-table .p-datatable .p-datatable-tbody > tr > td,
.primary-table .p-datatable .p-datatable-tbody > tr > td p {
  font-size: 15px;
  color: #333;
  border-color: #fff;
  padding: 6px;
}

.primary-table .p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background: #fff;
}

.primary-table .p-datatable .p-datatable-tbody > tr:nth-child(odd) {
  background: #f4f4f4;
}

.primary-table .p-checkbox .p-checkbox-box.p-highlight {
  background: #42997e;
  border-color: #42997e;
}

.primary-table .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #42997e;
}

.primary-table
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-highlight:hover {
  background: #42997e;
  border-color: #42997e;
}

.primary-table .p-column-filter-clear-button {
  display: none;
}

.primary-table .p-button.p-button-icon-only.p-button-rounded {
  height: auto;
}

.primary-table .p-button.p-button-icon-only {
  padding: 0;
}

.p-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.icon-edit:hover,
.icon-delete:hover {
  background: transparent !important;
}

.drone_request_icon {
  align-items: center;
  display: flex;
  color: orange;
}

.blue-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--blue-500);
}

.blue-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--blue-500);
}

.green-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--green-500);
}

.green-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--green-500);
}

.yellow-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--yellow-500);
}

.yellow-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--yellow-500);
}

.cyan-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--cyan-500);
}

.cyan-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--cyan-500);
}

.pink-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--pink-500);
}

.pink-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--pink-500);
}

.indigo-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--indigo-500);
}

.indigo-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--indigo-500);
}

.teal-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--teal-500);
}

.teal-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--teal-500);
}

.bluegray-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--bluegray-500);
}

.bluegray-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--bluegray-500);
}

.purple-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--purple-500);
}

.purple-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--purple-500);
}

.p-tooltip .p-tooltip-text {
  box-shadow: none !important;
}

.addnew {
  background-color: #ededed;
  color: #42997e;
  padding: 5px;
  cursor: pointer;
}

.download {
  color: #42997e;
  font-weight: bold;
}

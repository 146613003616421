.br-0 {
  border-radius: 0px !important;
}

.brd-none {
  border: none;
}
.tool-panal {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 60px;
  background: rgba($color: #fff, $alpha: 0.6);
  z-index: 1;
  padding: auto;
}

.control-panal {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 60px;
  background: rgba($color: #fff, $alpha: 0.6);
  z-index: 1;

  .client-logo {
    position: relative;
    top: 10px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-bottom: 30px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
    .avatar {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background: #858ce2;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: bold;
    }
  }
}

.tools-menu-end {
  transform: translate(55px, 0px) !important;
  min-width: 250px;
  max-width: max-content;
  .tool-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .dropdown-item {
      width: 100%;
    }
    button {
      flex: 50%;
    }
  }
}
.tools-menu-start {
  transform: translate(-55px, 0px) !important;
  padding: 0px;
  border-radius: 2px;
}
.layer-column {
  height: 300px;
  width: 220px;
  overflow-y: auto;
}

.layer-dropdown {
  display: flex;
  justify-content: space-between;
}

.top-select-panal {
  position: absolute;
  right: 0px;
  top: 0px;
  margin-top: 20px;
  margin-right: 50px;
  display: flex;
}

.map-container {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}

.bottom-layers {
  height: 90px;
  width: 80px;
  // background: url("../../assets/svg-new/Rectangle\ 12657.svg");
  // background-position: center;
  // background-repeat: no-repeat;
  // object-fit: cover;
  background-color: #069b80;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  box-sizing: border-box;
  border-radius: 6px;
  color: #fff;
}

.tools-menu-top {
  transform: translate(80px, -125px) !important;
  min-width: 300px;
  max-width: fit-content;
  .tool-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .dropdown-item {
      width: 100%;
    }
    button {
      flex: 50%;
    }
  }
}
.tools-menu-top1 {
  transform: translate(-20px, -125px) !important;
  min-width: 200px;
  max-width: 300px;
  .tool-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .dropdown-item {
      width: 100%;
    }
    button {
      flex: 50%;
    }
  }
}
.max-height-100 {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.toolModal {
  position: absolute;
  right: 3px;
  bottom: -26px;
  border-radius: 2px;
}
.toolModal1{
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  width: 450px;
}
.required {
  color: red;
}

.popover-itentifier {
  border: 2px solid #129fca;
  padding: 0;
  margin: 0;
  width: 500px;
  transform: translate(-50%, -100%);
  background: #fff;
  width: 250px;
  padding: 10px;
  border-radius: 5px;

  .pop-x {
    position: absolute;
    top: -12px;
    right: -10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 1px;
    background: #fff;
    border: 1px solid #129fca;
  }
  .pop-v {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 100%;
    left: 50%;
    background: #fff;
    border: 2px solid #129fca;
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: -4px;
    border-top: none;
    border-left: none;
  }
}

.custom-rotate-controls {
  position: fixed;
  left: 87%;
  bottom: 20%;
  img {
    width: 60px;
    margin: 5px auto;
  }
}

.img-container {
  height: 60px;
  width: 100px;
  object-fit: contain;
  margin-right: 10px;
}

.video-content {
  display: flex;
  flex-direction: column;
}

.arrow-lx {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #00bd9c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrow-lxl {
  position: relative;
  left: -4px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #00bd9c;
  border: 5px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrow-lxr {
  position: relative;
  right: -4px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #00bd9c;
  border: 5px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.screenshot-img {
  width: 765px;
  object-fit: contain;
  img {
    width: 100%;
  }
}
.del-x {
  position: relative;
  top: -75px;
  left: 135px;
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tooldropdowndraw {
  position: absolute;
  top: 20%;
  right: 65px;
}
.tooldropdownbuffer {
  position: absolute;
  top: 73%;
  right: 65px;
}
.tooldropdownprofileview {
  position: absolute;
  top: 55%;
  right: 65px;
  width: 185px;
  .card-body {
    justify-content: center;
  }
}
// .measuretools {
//   position: absolute;
//   // top: 50%;
//   left: 65px;
//   width: 200px;
//   .card-body {
//     justify-content: space-between;
//   }
// }
.img-screenshot {
  height: 160px;
  width: 285px;
  object-fit: contain;
  .del-screenshot {
    position: absolute;
    top: 4px;
    right: 9px;
    background: #fff;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.h-70 {
  max-height: 70vh;
  overflow-y: auto;
}
.full-sceenshot {
  position: absolute;
  top: 65px;
  left: 42%;
  color: #fff;
}
.ril__toolbarRightSide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  padding-right: 20px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.text-mos {
  font-family: Montserrat, SemiBold;
  font-weight: 600;
  font-size: 16px;

}
.d-none{
  display: none !important;
}
.oy{
  height: 100vh;
  overflow-y:auto ;
}
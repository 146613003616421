@import "../scss/icons.scss";
@import "../scss/config/saas/bootstrap.scss";
@import "../scss/config/saas/app.scss";
@import "../scss/config/saas/custom.scss";
@import "./shepherd.css";
@import "./mermaid.min.css";
@import "./common.scss";

//RTL
// @import "../scss/rtl/components-rtl";
// @import "../scss/rtl/layouts-rtl";
// @import "../scss/rtl/plugins-rtl";
// @import "../scss/rtl/pages-rtl";

//Rigthsidebar offcanvas
.offcanvas-header-dark {
  .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
  }
}

body {
  * {
    outline: none;
  }
}
.noUi-horizontal .noUi-handle {
  height: 16px !important;
  width: 16px !important;
  border-radius: 50% !important;
  right: -10px !important;
  top: -7px !important;
}
.noUi-connect,
.noUi-horizontal {
  height: 2px !important;
  background: #13c56b !important;
}
.noUi-handle {
  background: #13c56b !important;
  border: 2px solid var(--vz-card-bg) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.noUi-target {
  background: #fafafa !important;
  border-radius: 4px !important;
  border: 1px solid #d3d3d3 !important;
  background-color: var(--vz-light) !important;
  border-color: var(--vz-light) !important;
  box-shadow: none !important;
  height: 4px !important;
}
.dropzone .dz-message {
  text-align: center;
}

// //Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}
.fc-event {
  border: none !important;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;
    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }
  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }
  .fc-today-button {
    display: none;
  }
}
.flatpickr-calendar.open {
  z-index: 1090 !important;
}
.upcoming-scheduled .flatpickr-input[readonly] {
  display: none;
}
.ql-container.ql-snow {
  height: 250px;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #6691e7 !important;
}

// .Toastify__toast{
//   min-height: 43px !important;
//   max-height: 43px !important;
// }

@each $color, $value in $theme-colors {
  .simplebar-track-#{$color} {
    .simplebar-scrollbar:before {
      background: $value !important;
    }
  }
}

// chat
.chat-conversation .chat-list .message-box-drop {
  .nav-btn {
    font-size: 18px;
    padding: 4px;
    color: $gray-600;
  }
}

// Authentication pages
#tsparticles {
  canvas {
    position: absolute !important;
  }
}

// landing page
.process-card {
  .process-arrow-img {
    left: auto;
    right: 75%;
    transform: scaleX(-1);
  }
}
.icon-effect {
  &::before {
    left: auto;
    right: 0;
  }
}

.marketplace-swiper {
  .swiper-button-next,
  .swiper-button-prev {
    top: 22px;
  }
}

.alert-msg-show {
  right: 24px;
  top: 40px;
  z-index: 1005;
}

// Emoji Picker

.pickerEmoji {
  position: absolute;
  bottom: -50px;
  left: 10%;
  transform: translateX(-50%);
}

// Toast
.Toastify {
  .Toastify__toast {
    min-height: 43px;
  }
  .Toastify__close-button {
    top: 6px;
  }
  .Toastify__close-button--light {
    color: $light;
  }
}

// Tranfer List

.rdl-filter,
.rdl-control {
  background-color: $input-bg;
  border: 1px solid $input-border-color !important;
  color: $input-color !important;
}

.rdl-move {
  background: var(--#{$prefix}light) !important;
  border: 1px solid var(--#{$prefix}light) !important;
  color: var(--#{$prefix}body-color) !important;
}

.react-dual-listbox {
  input:disabled,
  select:disabled {
    background-color: $input-disabled-bg !important;
    border: 1px solid $input-border-color !important;
  }
}

.css-26l3qy-menu {
  background-color: var(--#{$prefix}gray-200) !important;
  border-color: var(--#{$prefix}border-color) !important;
}

.css-1n7v3ny-option {
  background-color: var(--#{$prefix}gray-300) !important;
  border-color: var(--#{$prefix}border-color) !important;
}
.side-bar {
  width: 30%;
  background-color: #6d8081;
  padding-top: 40%;
  height: fit-content;
}
.right-table {
  padding: 20px;
  background-color: #437b7e;
}
.astriek {
  padding-left: 5px;
  color: red;
  font-size: 16px;
}
.sec-main {
  align-content: end;
  padding: 10px;
}
.sec-one {
  border-radius: 4px;
  border-color: red;
  background-color: cornsilk;
}
.sec-two {
  flex: auto;
  label {
    margin-right: 10px;
  }
}
.moveback {
  padding-left: 2px;
  color: hsl(165, 96%, 27%);
  font-size: 15px;
}
.sec-card {
  background-color: #437b7e;
  border-radius: 20px;
}
.smetable {
  padding: 15px, 30px;
}
.colwidth {
  width: 140px;
  min-width: 100%;
}
.swiper-slide {
  img {
    max-height: 140px;
    width: 100%;
    object-fit: fill;
    object-position: center;
  }
}
.hidePageNumber .active a {
  display: none;
}

.smemenubar {
  width: fit-content;
  margin: 0;
  color: "#30B9CC";
}
.selectbox {
  border-color: #13c56b;
}
.carouselCss {
  padding: 30px, 40px !important;
}
.carousel-item.active {
  .d-none {
    display: block !important;
    p {
      display: none;
    }
  }
}
.carousel-inner {
  width: 60% !important;
  margin-left: 20%;
  padding-top: 30px;
  height: 80vh;
}
.client-margin {
  margin-bottom: 10px;
}
.labelmargin {
  margin-bottom: 2px;
}
.towerCard {
  height: 40%;
  width: 40%;
  background-color: #6d8081;
  .towerheader {
    background-color: #21c6db;
    padding: 20px;
  }
}
.client-bgsuccess {
  padding: 15px;
  background-color: #2e704f;
}
.marleft {
  margin-left: 30px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Poppins-Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins-Bold"),
    url("../src/assets/fonts/Poppins-Bold.ttf") format("ttf");
}

@font-face {
  font-family: "Poppins-Light";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins-Light.ttf"),
    url("../src/assets/fonts/Poppins-Light.ttf") format("ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins-Medium"),
    url("../src/assets/fonts/Poppins-Medium.ttf") format("ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins-Regular"),
    url("../src/assets/fonts/Poppins-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins-SemiBold"),
    url("../src/assets/fonts/Poppins-SemiBold.ttf") format("ttf");
}

.loader-main {
  background: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 150vh;
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: show;
}

.loader-box {
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
}

.spinner-style {
  width: 50px !important;
  height: 50px !important;
}

.loading-style {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 5px 15px;
  color: #333;
  font-weight: bold;
}

.primary {
  color: #42997e;
}
/* .primary:hover{
  background: #42997e;
  color: #0dc0c0;
} */

.m-20 {
  margin: 20px;
}

.m-25 {
  margin: 25px;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-20 {
  margin-bottom: 20px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-b-50 {
  margin-bottom: 50px;
}

.display-flex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-20 {
  margin-right: 20px;
}

.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-t-35 {
  margin-top: 35px !important;
}
.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-25 {
  padding: 25px;
}

.p-20 {
  padding: 20px !important;
}
.p-t-20 {
  padding-top: 20px;
}
.p-b-0 {
  padding-bottom: 0px !important;
}
.p-r-0 {
  padding-right: 0px !important;
}

.border {
  border: 2px solid black;
}
.border-black {
  border: 1px solid black;
}
.red {
  color: red;
}
.h-40 {
  height: 40px !important;
}

.ht-50 {
  height: 50px !important;
}

.grey-color {
  color: #7d7d7d;
}

.height-87 {
  height: 87vh;
}

.fs-20 {
  font-size: 20px;
}

.BreadcrumHome {
  align-items: center;
  display: inline-flex;
}

.w-15 {
  width: 15%;
}

.Open-Sans {
  font-family: "Open Sans";
}

.color-A5A5A5 {
  color: #a5a5a5;
}

.cards-links {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: normal;
  color: #42997e;
}

.pointer {
  cursor: pointer !important;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.6;
}

.homecard {
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 4px;
  opacity: 1;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0px !important;
}

.p12-20 {
  padding: 12px 20px !important;
}

.mt5 {
  margin-top: 5px;
}

.w-50vw {
  width: 50vw;
}

.pilot-color {
  border: 0.5px solid #42997e;
  background-color: #42997e12;
  border-radius: 12px;
  font-size: 18px;
  padding: 18px 79px;
  display: inline-block;
}
.pilotname {
  color: #a5a5a5;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.h-30 {
  height: 30%;
}

.overflow {
  overflow: auto;
}

.h-85 {
  height: 85% !important;
}

.p-autocomplete-input {
  width: 100%;
}

.mt-25 {
  margin-top: 25px !important;
}

.display-none {
  display: none;
}

.w-20 {
  width: 20% !important;
}

.iframe-style {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.h-0 {
  height: 0% !important;
}
.h-20 {
  height: 20% !important;
}

.h-170 {
  height: 170px;
}

.w-100 {
  width: 100% !important;
}

.p-password-input {
  width: 100% !important;
}
.p-card-content {
  padding: 0px !important;
}
.opacity-9 {
  opacity: 0.9;
  color: grey;
}
.opacityy-9 {
  opacity: 0.9;
  color: rgb(115, 113, 113);
}
.border-surveys {
  margin: 20px auto;
  height: 45px;
  width: 45px;
  border: 2px solid #0dc0c0;
  border-radius: 50%;
  font-size: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
}

@media (max-width: 1583px) and (min-width: 576px) {
  .border-surveys {
    font-size: 50px !important;
    margin-top: 30px !important;
  }
}
.survey {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.surveys {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.viewmore {
  display: flex;
  justify-content: center;
  color: #0dc0c0;
}

.cardcolumn {
  padding: 0px;
  margin-bottom: 10px;
}
@media (max-width: 1920px) and (min-width: 1200px) {
  .lgcol {
    -ms-flex: 0 0 20% !important;
    flex: 0 0 20% !important;
    max-width: 22.5% !important;
  }
}
@media (max-width: 575px) {
  .dibox {
    background: white;
    margin-right: 0px !important;
  }
}
.imageboxdiv {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: auto;
  display: flex;
  flex-direction: row;
  align-content: stretch;
  align-items: stretch;
}
.contextbox {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 3px 6px #00000029 !important;
}
.contextboxhome {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  height: 80px;
  position: relative;
  box-shadow: 0px 3px 6px #00000029 !important;
}
.pointer-none {
  pointer-events: none;
}
.dibox {
  background: white;
  width: 100%;
  height: auto;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bg-f5f5f5 {
  background-color: #f5f5f5;
}
.fs-16 {
  font-size: 16px;
}
.w-fit {
  width: fit-content;
}

/* width */
::-webkit-scrollbar {
  cursor: pointer !important;
  background-color: #f5f5f5;
  width: 9px;
  height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.w-345 {
  width: 345px;
}
.color-D31414 {
  color: #d31414;
}
.float-right {
  float: right;
}

.dcolor .p-highlight {
  background: #42997e !important;
  border-color: #42997e !important;
}
.actionupload .p-fileupload-choose {
  background: #42997e !important;
  border-color: #42997e !important;
  height: 40px;
}
.infoMessage {
  background-color: #ffdade;
  border: 1px solid #dc3545;
  font-size: 15px;
  color: #dc3545;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: white !important;
  border-color: white !important;
  color: #42997e !important;
}
.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item
  .p-component
  .p-highlight {
  background: #42997e !important;
  border-color: #42997e !important;
}

.p-multiselect-panel .p-multiselect-header .p-checkbox-checked .p-highlight {
  background: #42997e !important;
  border-color: #42997e !important;
}

.mH300 {
  max-height: 300px;
}

.admin-header-new {
  width: 100%;
  background-color: #003975;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}
.closedSidebar {
  background: #003975;
  height: 100vh;
  width: 80px;
}
.openedSidebar {
  background: #003975;
  height: 100vh;
  width: 310px;
}
.openedSidePanal {
  width: calc(100vw - 310px);
  height: 100vh;
}
.closedSidePanal {
  width: calc(100vw - 80px);
  height: 100vh;
}
.openbar-s {
  margin-right: 35px;
}
.dashboard-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashboard-items2 {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 16px;
  padding-top: 16px;
}
.page-admin {
  height: calc(100vh - 8vh);
  background: #eee;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 30px;
}

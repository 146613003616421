//
// _card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $card-box-shadow;
}
.card-body{
  padding: 0px;
}
.card-header {
  border-bottom: 1px solid $border-color;
}

//Card Header Dropdown
.card-header-dropdown {
  .dropdown-btn {
    padding: $card-cap-padding-y 0;
  }
}

.card-footer {
  border-top: 1px solid $border-color;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 16px;
  margin: 0 0 7px 0;
}

.card-title-desc {
  margin-bottom: 24px;
}

.card-height-100 {
  height: calc(100% - #{$grid-gutter-width});
}

.card-animate {
  transition: all 0.5s;

  &:hover {
    transform: translateY(calc(#{-$card-animate-rise} / 5));
    box-shadow: $box-shadow-lg;
  }
}

// card color variant

@mixin card-variant($bg) {
  color: $white;
  background-color: $bg;
}

@each $color, $value in $theme-colors {
  .card-#{$color} {
    @include card-variant($value);

    .card-header,
    .card-footer {
      background-color: rgba($white, 0.1);
      color: $white;
      border-color: transparent;
    }

    .card-title {
      color: $white;
    }
  }
}

@each $color, $value in $theme-colors {
  .card-border-#{$color} {
    border-color: $value !important;
    .card-header,
    .card-footer {
      border-color: $value;
    }
  }
}

.card-light {
  background-color: var(--#{$prefix}light);
  .card-header,
  .card-footer {
    color: var(--#{$prefix}body-color) !important;
    background-color: rgba(var(--#{$prefix}dark-rgb), 0.1);
  }
  .card-title,
  .card-text {
    color: var(--#{$prefix}body-color) !important;
  }
}

.stretched-link {
  position: static;
}

// Loader

.card-preloader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--#{$prefix}light-rgb), 0.6);
  z-index: 9999;
}

.card-status {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
}

.custom-loader {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.card-overlay {
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    background-color: rgba($primary, 0.2);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .card-header,
  .card-footer {
    border-color: rgba($white, 0.15) !important;
  }
}

.card-toolbar-menu {
  line-height: 0.8;
  a {
    font-size: 17px;
  }
  .minimize-card {
    .plus {
      display: none;
    }
    .minus {
      display: block;
    }
    &.collapsed {
      .plus {
        display: block;
      }
      .minus {
        display: none;
      }
    }
  }
}
// .badge {
//   background-color: rgba(var(--#{$prefix}success-rgb), 0.15) !important;
// }
.status_text {
  background: $white;
  border: 1px solid;
  border-radius: 12px;
  padding: 6px 2px;
}
.status_text1 {
  background: $white;
  border: 1px solid;
  border-radius: 4px;
  padding: 6px 2px;
}
.pdg {
  padding: "4px 0px 0px 2px";
  margin-right: 1px;
}

.cardbody-client {
  font-family: "Poppins", sans-serif;
  font-size: 23px;
  cursor: pointer;
  padding: 8px 16px;
}
.cardbody-client1 {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  cursor: pointer;
  padding: 2px 8px;
}
.custom-card {
  box-shadow: 0px 1px 2px #38414a26;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px !important;

  .card-head {
    .card-title {
      font-size: 18px;
    }

    .card-address {
      font-size: 14px;
    }

    .card-count-title {
      font-size: 12px !important;
      color: #242529 !important;
      text-transform: capitalize !important;
      opacity: 1 !important;
    }
    i {
      color: "red";
      font-size: 18px;
    }
  }
}
.cardfooter-client {
  // display: flex;
  // align-items: center;
  padding: 0px, 8px !important;
  justify-content: space-between;
}
.colchng {
  font-family: "Poppins,Medium";
  font-size: 8px;
  font-weight: bold;
}

.project-outer {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.bg-96c6b72e {
  background-color: #96c6b72e !important;
}
.h-80vh {
  height: 80vh;
}
.project-font {
  color: #646464;
  font-weight: bold;
  font-size: 24px;
}
.project-para {
  font-size: 18px;
  color: #646464;
}
.project-button {
  background-color: #42997efa;
  margin-top: 20px;
}

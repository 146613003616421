.steps-container {
  height: 65px;
  width: 100%;
  background-color: white;
  padding-top: 20px;
  margin-bottom: 2px;
}
.border-right {
  border-right: 1px solid #e9e8e4;
}
.left {
  float: left;
}
.steps {
  color: #9e9e9e;
  height: 27px;
  width: 27px;
  border: 1px solid #9e9e9e;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}
.active-txt-color {
  color: black;
}
.activePage {
  color: white;
  background-color: #22992e;
}
.border-top-bottom {
  border-top: 1px solid #e9e8e4;
  border-bottom: 1px solid #e9e8e4;
  padding-bottom: 60px;
}
.border-top {
  border-top: 1px solid #e9e8e4;
  padding-bottom: 5px;
}
.project-outer {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.bg-96c6b72e {
  background-color: #96c6b72e !important;
}
.h-80vh {
  height: 80vh;
}
.project-font {
  color: #646464;
  font-weight: bold;
  font-size: 24px;
}
.h-80 {
  height: 80%;
}
.w-80 {
  width: 80%;
}

.create-user-card {
  margin: 0px 20px !important;
  height: 80vh !important;
  padding: 20px;
  overflow: hidden;
}
.priv-table {
  height: 43vh !important;
  overflow: hidden;
}
.h-7 {
  height: 7vh !important;
}
.h-69vh {
  height: 69vh !important;
}
.bt-none {
  border-top: none !important;
}
.client-main {
  height: 85vh !important;
  overflow: hidden;
}
.nodata {
  width: 100%;
  text-align: center;
  margin: auto;
}
.card-head-height{
  height: 7vh !important;
}
@media only screen and (min-width: 1280px) {
  .card-head-height {
    height: 8vh !important;
  }
}
@media only screen and (min-width: 1600px) {
  .create-user-card {
    height: 84vh !important;
    padding: 20px;
  }
  .card-head-height {
    height: 7vh !important;
  }
  .priv-table {
    height: 37vh !important;
    overflow: hidden;
  }
}
